import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';

const PANEL_ID = 'poster-editor-fancy-text-panel';

export default function PosterEditorFancyTextModal(): ReactElement {
  const height = '192px';
  const maxWidth = '800px';

  return (
    <Modal
      modalId={MODALS_IDS.POSTER_EDITOR_FANCY_TEXT_MODAL}
      panelId={PANEL_ID}
      modalHeight={height}
      mobileHeight={height}
      isBottomSheetOnMobile
      isBottomSheetNonBlocking
      modalMaxWidth={maxWidth}
      modalWidth="100%"
    >
      <LazyPanel
        panelDirectoryName="poster-editor-fancy-text-panel"
        panelProps={{
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openPosterEditorEditFancyTextModal = (): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.POSTER_EDITOR_FANCY_TEXT_MODAL,
      panelProps: {
        panelId: PANEL_ID,
      },
    })
  );
};
